import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Navigation from "../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import ReactGA from "react-ga";
import Twitter from "../images/icons/twitter.png";
import YouTube from "../images/icons/youtube.png";
import LinkedIn from "../images/icons/linkedin.png";
import Github from "../images/icons/github-logo.png";
import HeadShot from "../images/head_shot_3.jpg";
import Image1 from "../images/saas-side-hustle/1.jpg";
import Image2 from "../images/saas-side-hustle/2.png";
import PDF from "../files/saas-side-hustle.pdf";
import Button from "react-bootstrap/Button";
import BlogHero from "../components/blog-hero";

// Icon Pack: https://www.flaticon.com/packs/social-media-icons
// Icon Pack: https://www.flaticon.com/free-icon/github-logo_25231

class SaaSSideHustle extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    ReactGA.initialize("UA-115464417-1");
    if (!(window.location.hostname === "localhost")) {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    // window.addEventListener('scroll', this.handleScroll);
  }

  handleClick = (event) => {

    ReactGA.event({
      category: 'Buttons',
      action: 'Click',
      label: event.target.id
    });

  }

  downloadButtonClicked = (e) => {
  }

  handleChange = (event) => {
  }

  handleScroll = (event) => {
  }

  render() {
    return (
      <Layout>
        <SEO
          title="How To Build A Micro-SaaS side-hustle That Actually Makes Money"
          keywords={[
          ]}
        />

        {/* <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', textAlign: 'center', backgroundColor: 'red', zIndex: 1000 }}>
          <a href="https://quinston.gumroad.com/l/micro-saas-side-hustle/saas20"><Button id="download-button" variant="danger" style={{ width: '100%', fontSize: 'larger' }}>Use code 'SAAS20' for 20% Off</Button></a>
        </div> */}

        <div style={{ marginTop: 20 }} />

        <Container style={{ maxWidth: 750 }}>

          <Row>
            <Col style={{ textAlign: 'center' }}>
              <div style={{ marginTop: '4rem' }}></div>
              <p style={{ fontWeight: 300, fontSize: 'medium', fontWeight: 500 }}>Attention: Programmers, Hackers and Tinkerers</p>
              <p>
                <h1 style={{ fontWeight: 900, lineHeight: 1.4 }}>
                  Build A Micro-SaaS side-hustle That Actually Makes Money
                </h1>
              </p>
              <p style={{ fontWeight: 300, fontSize: 'larger' }}>A super-dense 40-page book about what you should pay attention to while building a Micro-SaaS Product on your own!</p>
              <div style={{ marginTop: '2rem' }}></div>
            </Col>
          </Row>

          <Row style={{
            padding: '1rem',
            // border: '1px solid #F5F5F',
            margin: 1,
            backgroundColor: 'white'
          }}>
            <Col>

              <Row style={{ marginBottom: 30 }}>
                <Col>
                  <a
                    href="https://quinston.lemonsqueezy.com/buy/e9f6ff5e-ece8-4a0d-9478-541b60c28096"
                    target="_blank"
                  >
                    <Button id="download-button" variant="danger" style={{ width: '100%' }}>Get PDF For Free</Button>
                  </a>
                </Col>
              </Row>

              {/* <Row>
                <Col style={{ fontSize: '0.7em', textAlign: 'center' }}>
                  30-Day Refund Policy | <a href="https://app.box.com/s/eqy60nb1yicxm6qh02s3pqy2p38u1i7s" target="_blank" style={{ textDecoration: 'underline' }}>Read the First Chapter For Free</a>
                </Col>
              </Row> */}

              <Row>
                <Col style={{ fontSize: '0.7em', textAlign: 'center' }}>
                  Send me a DM if you enjoyed reading it!
                </Col>
              </Row>

            </Col>
          </Row>

        </Container>

        <Container style={{ maxWidth: 750, marginTop: '2rem' }}>

          <Row style={{ marginBottom: '1rem' }}>
            <Col>

              <h2 style={{ marginBottom: 0 }}>
                  About Me
              </h2>

            </Col>
          </Row>

          <Row style={{ textAlign: 'left' }}>
            <Col>
              <p>
                  My name is Quinston Pimenta and this book is a culmination of all the learnings I've had from co-founding TimeBolt.io with a team of 2 people (me included).
              </p>
            </Col>
          </Row>

          <Row style={{ textAlign: 'left' }}>
            <Col>
              <p>
                I have a background in software but my diverse experience across product development, design, and business provides me with a unique perspective to create simple solutions for complex problems using technology.
              </p>
            </Col>
          </Row>

        </Container >

        <Container style={{ maxWidth: 750, marginTop: '1rem' }}>

          <Row style={{ marginBottom: '1rem' }}>
            <Col>

              <h2 id="faqfrequentlyaskedquestions">
                  See what our readers say
              </h2>

            </Col>
          </Row>

          <Row>

            <Col sm={12} lg={6} style={{ marginBottom: '2rem' }}>
              <Row style={{
                padding: '.5rem',
                border: '1px solid #F5F5F5',
                backgroundColor: 'white',
                textAlign: 'left',
                margin: 1,
                height: '100%',
              }}>
                <Col>
                  <h3 style={{ marginBottom: 0 }}>Rajan S</h3>
                  <p style={{ marginBottom: 10 }}>⭐⭐⭐⭐⭐</p>

                  <p style={{ fontStyle: 'italic', marginBottom: 10, color: 'grey' }}>Rename it to 'The Side Hustle Bible'</p>
                  <p style={{ fontSize: 'medium', margin: 0 }}>Quinston's play-by-play gives a clear insight into the mindset you need to have when you go from building a side hustle to launching it and eventually selling/raising funds for it. Rename it to 'The Side Hustle Bible'.</p>
                </Col>
              </Row>
            </Col>

            <Col sm={12} lg={6} style={{ marginBottom: '2rem' }}>
              <Row style={{
                padding: '.5rem',
                border: '1px solid #F5F5F5',
                backgroundColor: 'white',
                textAlign: 'left',
                margin: 1,
                height: '100%'
              }}>
                <Col>
                  <h3 style={{ marginBottom: 0 }}>Saampatii V</h3>
                  <p style={{ marginBottom: 10 }}>⭐⭐⭐⭐⭐</p>

                  <p style={{ fontStyle: 'italic', marginBottom: 10, color: 'grey' }}>"This book is a gem!"</p>
                  <p style={{ fontSize: 'medium', margin: 0 }}>Everything in the book is explained very well. Thank you for providing such great insights! :)</p>
                </Col>
              </Row>
            </Col>
            
          </Row>

          <Row>

            <Col sm={12} lg={6} style={{ marginBottom: '2rem' }}>
              <Row style={{
                padding: '.5rem',
                border: '1px solid #F5F5F5',
                backgroundColor: 'white',
                textAlign: 'left',
                margin: 1,
                height: '100%'
              }}>
                <Col>
                  <h3 style={{ marginBottom: 0 }}>Abhishek L</h3>
                  <p style={{ marginBottom: 10 }}>⭐⭐⭐⭐⭐</p>

                  <p style={{ fontStyle: 'italic', marginBottom: 10, color: 'grey' }}>"It really hooked me."</p>
                  <p style={{ fontSize: 'medium', margin: 0 }}>First of all, this was my first ever book that I read completely! The most amazing thing about it was the number of pages it had. It really hooked me. Also, it created an interest in me to read more, thanks to you! Got to know the monetization of the software really well.</p>
                </Col>
              </Row>
            </Col>

            <Col sm={12} lg={6} style={{ marginBottom: '2rem' }}>
              <Row style={{
                padding: '.5rem',
                border: '1px solid #F5F5F5',
                backgroundColor: 'white',
                textAlign: 'left',
                margin: 1,
                height: '100%'
              }}>
                <Col>
                  <h3 style={{ marginBottom: 0 }}>Ali Z</h3>
                  <p style={{ marginBottom: 10 }}>⭐⭐⭐⭐⭐</p>

                  <p style={{ fontStyle: 'italic', marginBottom: 10, color: 'grey' }}>"A great learning resource for anyone interested in building their own side hustle."</p>
                  <p style={{ fontSize: 'medium', margin: 0 }}>The explanation was concise, lucid, and offered a very practical perspective. There are thousands of books in the market that will teach you how to code, but not so many that tell you how to turn that knowledge into an actual selling product.</p>
                </Col>
              </Row>
            </Col>
            
          </Row>

          <Row style={{
            padding: '.5rem',
            border: '1px solid #F5F5F5',
            marginBottom: '4rem',
            backgroundColor: 'white',
            textAlign: 'left',
            margin: 1,
            height: '100%'
          }}>
            <Col>
              <h3 style={{ marginBottom: 0 }}>Vikrant J</h3>
              <p style={{ marginBottom: 10 }}>⭐⭐⭐⭐⭐</p>

              <p style={{ fontStyle: 'italic', marginBottom: 10, color: 'grey' }}>"You get exactly what's promised in the title."</p>
              <p style={{ fontSize: 'medium', margin: 0 }}>This book is a very clear and concise arrangement of ideas extremely relevant for anyone who has an idea for a product or the technical skills to build one! You get exactly what's promised in the title. The no-bullshit tone of the book, not only shows you the reality of developing a product but also shows you the actual space for opportunity and improvement. Great read!</p>
            </Col>
          </Row>

        </Container >

        <div style={{ marginTop: 100 }}></div>

        <Container style={{ maxWidth: 650, textAlign: 'center' }}>

          <Row>
            <Col>

              <p>If you have any further questions, shoot me an email on <b>mail@quinston.com</b> or hit me up on Twitter <a href="https://twitter.com/quinstonpimenta" target="_blank" style={{ textDecoration: "underline" }}>@QuinstonPimenta</a></p>

              <p>Have a good one!</p>

            </Col>
          </Row>

        </Container >

        <div style={{ marginTop: 100 }}></div>

        {/* <a href="#pricing">
					<Button style={{ borderRadius: 0 }} id="apply-now-bottom" variant="primary" size={'lg'} onClick={this.handleClick} block></Button>
				</a> */}

      </Layout >
    );
  }
}

export default SaaSSideHustle;
